import TopBar from '@/components/TopBar/TopBar.vue'
import emitter from '@/utils/emitter'
import Notice from './notice.vue'
import { throttle } from 'lodash'
import { mapActions } from 'vuex'

// 获取token
// import { apiToken } from '@/api/api.js'
export default {
  components: {
    TopBar: TopBar,
    Notice,
  },
  data() {
    return {
      SuggestView: false,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    backgroundColor() {
      const { backgroundColor } = this.$route.meta
      return backgroundColor || '#fff'
    },
    isFull() {
      const { full } = this.$route.meta
      return !!full
    },
    fullscreen() {
      const { fullscreen } = this.$route.meta
      return !!fullscreen
    },
  },
  watch: {
    backgroundColor: {
      immediate: true,
      handler() {
        document.body.style.backgroundColor = this.backgroundColor
      },
    },
  },
  mounted() {
    // this.getToken()
    this.SuggestView =
      navigator.userAgent.indexOf('Chrome') === -1 &&
      navigator.userAgent.indexOf('CriOS') === -1

    emitter.on('SCROLL_TOP', this.scrollFunc.bind(this))

    window.addEventListener('scroll', this.handleScroll)

    // 获取用户信息
    this.GetUserInfo()

    // 10分钟自动刷新一次
    this.forceUpdateInfo()

    // 用户从后台打开页面，自动获取用户
    this.activePage()
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    forceUpdateInfo() {
      setTimeout(() => {
        this.GetUserInfo()

        this.forceUpdateInfo()
      }, 1000 * 60 * 10)
    },
    activePage() {
      //chrome,ff,360极速
      const bowhidden =
        'hidden' in document
          ? 'hidden'
          : 'webkithidden' in document
          ? 'webkithidden'
          : 'mozhidden' in document
          ? 'mozhidden'
          : null
      const vibchage =
        'visibilitychange' || 'webkitvisibilitychange' || 'mozvisibilitychange'
      document.addEventListener(vibchage, () => {
        if (!document[bowhidden]) {
          this.GetUserInfo()

          emitter.emit('VISIBILITY')
        }
      })
    },

    closeSuggest() {
      this.SuggestView = false
    },
    scrollFunc(topVal) {
      window.scrollTo({ top: topVal })
    },
    handleScroll: throttle(() => {
      emitter.emit('SCROLL', window.scrollY)
    }, 50),
    // getToken() {
    //     let Token = sessionStorage.getItem('token');
    //     // console.log(Token)
    //     if (Token == null) {
    //         apiToken().then((res) => {
    //             // console.log(res.data)

    //         })
    //     }
    //     // console.log('aaa', sessionStorage.getItem('token'))
    // }
  },
}
