<template>
  <el-dialog
    custom-class="notice-dialog"
    title="重要通知"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :center="false"
  >
    <div class="notice-content">
      <p>由于业务重心转移，PTEGO将做出两项调整，特此通知。</p>
      <p>
        1. PTEGO APP即日起从所有APP STORE正式下线。网页版正常运行，请用电脑端设备登陆www.ptego.com网页版使用。
      </p>
      <p>
       2. PTEGO部分会员专属功能暂定于3月20日起进行调整。其中包括但不限于：口语上传和AI评分接口暂停使用，会员专享视频等移至B站/YouTube。部分会员功能将转为非会员也可使用的功能。
      </p>
      <p>付费用户客服通道已开通。如有疑问及需求，请发送email至service@ptego.com。</p>
      <p>谢谢大家的理解和支持，对于造成的不便，我们非常抱歉。</p>
    </div>

    <template>
      <div class="notice-btns">
        <el-button type="primary" size="small" @click="handlerSubmit"
          >确认阅读并关闭</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
const KEY = 'PTEGO_GLOBAL_NOTICE'
export default {
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    //const value = localStorage.getItem(KEY)
    //this.visible = !Number(value)
    this.visible = false
  },
  methods: {
    handlerSubmit() {
      this.visible = false
      localStorage.setItem(KEY, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.notice-content {
  > p {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.notice-btns {
  text-align: center;
  margin-top: 50px;
  ::v-deep {
    > .el-button {
      background-color: #3b86ff;
      border-color: #3b86ff;
    }
  }
}
</style>

<style lang="scss">
.notice-dialog {
  .el-dialog__header {
    text-align: left;
  }
}
</style>
