<template>
<el-dialog
  class="change-password-dialog"
  title="修改密码"
  :visible.sync="localVisible"
  width="317px"
  :before-close="handleClose"
  :append-to-body="true">
  <div v-if="error" class="error-tips">{{error}}</div>
  <div class="ipt-group">
    <label>原密码</label>
    <el-input
      placeholder="请输入原密码"
      v-model="oldPassword"
      class="input"
      :class="{error: errorType === 'oldPassword'}"
    />
  </div>
  <div class="ipt-group">
    <label>新密码</label>
    <el-input
      ref="newPassword"
      :type="inputType.newPassword"
      placeholder="请输入新密码"
      v-model="newPassword"
      class="input"
      :class="{error: ['checkPassword','newPassword'].includes(errorType)}"
    >
      <i
        :class="{active: inputType.newPassword === 'text'}"
        class="el-icon-view el-input__icon"
        slot="suffix"
        @click="changeType('newPassword')">
      </i>
    </el-input>
  </div>
  <div class="ipt-group">
    <label>确认新密码</label>
    <el-input
      ref="secondPassword"
      :type="inputType.secondPassword"
      placeholder="请输入新密码"
      v-model="secondPassword"
      class="input"
      :class="{error: ['checkPassword','secondPassword'].includes(errorType)}"
    >
      <i
        :class="{active: inputType.secondPassword === 'text'}"
        class="el-icon-view el-input__icon"
        slot="suffix"
        @click="changeType('secondPassword')">
      </i>
    </el-input>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" size="mini" @click="submit" :loading="loading">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
import { resetPwd, apiLoginout } from '@/api/api.js'

const ERROR_TYPE = {
  13: 'checkPassword',
  14: 'checkPassword',
  15: 'oldPassword',
  16: 'oldPassword',
}

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: true,
      localVisible: this.visible,
      oldPassword: '',
      newPassword: '',
      secondPassword: '',
      inputType: {
        newPassword: 'password',
        secondPassword: 'password',
      },
      error: '',
      loading: false,
      errorType: ''
    };
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    }
  },
  methods: {
    submit() {
      this.error = ''
      this.errorType = ''
      if (!this.oldPassword) {
        this.errorType = 'oldPassword'
        this.error = '请输入原密码'
        return
      }
      if (!this.newPassword) {
        this.errorType = 'newPassword'
        this.error = '请输入新密码'
        return
      }
      if (!this.secondPassword) {
        this.errorType = 'secondPassword'
        this.error = '请输入确认新密码'
        return
      }
      if (this.newPassword.length < 6) {
        this.errorType = 'newPassword'
        this.error = '修改密码最少6位'
        return
      }
      resetPwd({
          pwd_old: this.oldPassword,
          pwd_new: this.newPassword,
          pwd_new2: this.secondPassword,
        }).then((res) => {
          if(res.code !=200){
            return
          }
          if (res.data === 1) {
            this.$layer.msg("<div class='success-icon'></div><div>修改成功</div>")
            this.loginOut()
            return
          }
          this.error = res.msg
          this.errorType = ERROR_TYPE[res.data]
        }).finally(() => {
          this.loading = false
        })
    },
    changeType(type) {
      this.inputType[type] = this.inputType[type] === 'password' ? 'text' : 'password'
      this.$refs[type].focus()
    },
    loginOut() {
        apiLoginout().then((res) => {
            window.location.href = './'
        })
    },
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose(){
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss">
.change-password-dialog {
  padding: 0 16px;
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #EBEBF2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #A4AFB7;
    }
  }
  .el-button--primary {
    background: #3B86FF;
    border-color: #3B86FF;
    border-radius: 5px;
    padding: 4px 18px;
  }
  .el-icon-view {
    &.active {
      color: #3b86ff;
    }
  }
  .error-tips {
    padding: 5px 14px;
    line-height: 20px;
    background: rgb(255, 96, 109, 0.3);
    border-radius: 3px;
    font-size: 14px;
    color: #FF606D;
  }
  .ipt-group {
    padding-top: 20px;
    &:first-child {
      padding-top: 0;
    }
    > label {
      padding-bottom: 10px;
      font-size: 14px;
    }
    .input {
      margin-top: 10px;
      &.error {
        .el-input__inner {
          border: 1px solid #FF606D;
        }
      }
      .el-input-group__prepend {
        background-color: #fff;
      }
      .el-input__inner:hover {
        border-color: #3b86ff;
      }
      .el-input__inner:focus {
        border-color: #3b86ff;
        box-shadow: 0px 0px 0px 2px #bae7ff;
      }
    }
  }
}
</style>
