import { apiLoginout, apiUserInfo, apiPcNotifyList } from '@/api/api.js'
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue'
import emitter from '@/utils/emitter'
import { mapState } from 'vuex'
import i18n from '@/i18n/i18n'

const EXPIRE_VIP = 'EXPIRE_VIP'
const MENU = [
  {
    name: 'home',
    path: 'home',
    routerList: ['collect', 'home', 'ExerciseBook', 'FileDownload']
  },
  {
    name: 'exercises',
    path: 'ExercisesList',
    routerList: ['ExercisesList', 'Exercises']
  }
]
  .concat(
    i18n.locale === 'en'
      ? []
      : [
          {
            name: 'skill',
            path: 'course',
            routerList: [
              'course',
              'courseChapter',
              'chapterDetail',
              'chapterDetailText',
              'chapterDetailLive',
              'courseMyList',
              'courseAllList'
            ]
          }
        ]
  )
  .concat([
    {
      name: 'mockExam',
      path: 'examList',
      routerList: ['examList', 'examDetail', 'examTest']
    },
    {
      name: 'wordBook',
      path: 'wordBook',
      routerList: ['wordBook', 'wordList', 'wordLearn']
    },
    {
      name: 'forum',
      path: 'forum',
      routerList: ['forum']
    }
  ])

const getRouterObject = () => {
  return MENU.reduce((pre, next) => {
    const obj = {}
    next.routerList.forEach((name) => {
      obj[name] = { parentName: next.path }
    })
    return { ...pre, ...obj }
  }, {})
}

export default {
  components: {
    ChangePasswordDialog
  },
  data() {
    return {
      menu: MENU,
      lang: this.$i18n.locale,
      hasMessage: false,
      focusInput: false,
      loginOutVisible: false,
      isVip: 0,
      title: '', //标题
      keyword: '',
      activeMenu: '',
      routerObject: getRouterObject(),
      messageNumber: 0,
      showChangePasswordDialog: false,
      expireVisible: false,
      openViewVisible: sessionStorage.getItem('OPEN_VIP_TIP')
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    isHome() {
      return this.$route.name === 'home'
    },
    dropdownActive() {
      if (this.$route.name === 'home') {
        return false
      } else {
        return true
      }
    },
    i18n() {
      return this.$t('messages')
    }
  },
  watch: {
    userInfo() {
      this.showExpire()
    },
    $route(to, from) {
      this.setActiveMenu(to.name)
      this.setTitle(to.name, from.name)
      sessionStorage.setItem('routerTitle', from.name)
      if (this.focusInput && !['Exercises', 'Search'].includes(to.name)) {
        this.blurInput()
      }
    },
    '$i18n.locale'(newV, oldV) {
      location.reload()
    }
  },
  mounted() {
    this.setActiveMenu(this.$route.name)
    this.setTitle(this.$route.name)
    this.getMsgNumber()
  },
  methods: {
    blurInput() {
      this.keyword = ''
      this.focusInput = false
    },
    clickInput() {
      this.focusInput = true
      this.$refs.searchInput.focus()
    },
    getMsgNumber() {
      apiPcNotifyList().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.messageNumber = Number(res.data.unread_count)
      })
    },
    setActiveMenu(routeName) {
      const current = this.routerObject[routeName]
      if (!current) {
        this.activeMenu = ''
        return
      }
      if (current.parentName !== this.activeMenu) {
        this.activeMenu = current.parentName
      }
    },
    goMenu(item) {
      this.$router.push({ name: item.name })
    },
    setTitle(name, beforeName) {
      if (typeof name == 'object') {
        name = name[0]
      }
      if (typeof beforeName == 'object') {
        beforeName = beforeName[0]
      }
      this.title = name
      if (
        name == 'GrePracticeDetail' ||
        name == 'GreDetail' ||
        name == 'SkillExerciseTask' ||
        name == 'UserCenterExerciseBookDetailsTwo'
      ) {
        if (beforeName) {
          this.title = beforeName
        } else {
          this.title = sessionStorage.getItem('routerTitle')
        }
      }
      this.changeLang(localStorage.lang)
    },
    changeLang(lang) {
      switch (lang) {
        case 'en':
          this.$i18n.locale = 'en'
          localStorage.lang = 'en'
          break
        case 'ch':
          this.$i18n.locale = 'ch'
          localStorage.lang = 'ch'
          break
      }
      //window.location.reload()
    },
    handleCommand(command) {
      switch (command) {
        case 'changeLang':
          this.changeLang(this.$i18n.locale === 'ch' ? 'en' : 'ch')
          break
        case 'goCollect':
          this.$router.push({ name: 'collect' })
          break
        case 'logOut':
          this.loginOutVisible = true
          break
        case 'changePassword':
          this.showChangePasswordDialog = true
          break
        case 'userInfo':
          this.$router.push({ name: 'userInfo' })
          break
        case 'goMessage':
          this.goMessage()
          break
      }
    },
    handleDropdown(command) {
      switch (command) {
        case 'PTE':
          this.$router.push({ name: 'course' })
          break
        case 'IELT':
          this.$router.push({ name: 'IELTScourse' })
          break
      }
    },
    handlePasswordDialog() {
      this.showChangePasswordDialog = !this.showChangePasswordDialog
    },
    clickLoginOut() {
      apiLoginout().then((res) => {
        window.location.href = './'
      })
    },
    goVip() {
      this.$router.push({ name: 'vip' })
    },
    goMessage() {
      this.$router.push({ name: 'Message' })
    },
    goSearch() {
      if (!Number(this.keyword)) {
        let maxCount = /[\u4e00-\u9fa5]/gi.test(this.keyword) ? 2 : 3
        if (String(this.keyword).trim().length < maxCount) {
          return this.$message.warning('至少要输入3位以上的字母搜索')
        }
      }

      this.$router.push({
        name: 'Search',
        query: {
          keyword: this.keyword
        }
      })
      this.$refs.searchInput && this.$refs.searchInput.focus()
    },
    clearKeywork() {
      this.keyword = ''
      this.focusInput = false
      this.$router.push({ name: 'home' })
    },
    goRecord() {
      this.$router.push({ name: 'vipRecord', query: { type: 'use' } })
    },
    showExpire() {
      const expireDay = Number(localStorage.getItem(EXPIRE_VIP))
      if (
        this.userInfo.vip_day_last <= 7 &&
        this.userInfo.vip_day_last &&
        this.userInfo.vip_day_last !== expireDay
      ) {
        this.expireVisible = true
      }
    },
    closeExpireTips() {
      this.expireVisible = false
      localStorage.setItem(EXPIRE_VIP, this.userInfo.vip_day_last)
    },
    handleCloseVipTip() {
      sessionStorage.setItem('OPEN_VIP_TIP', 1)
      this.openViewVisible = 1
    }
  }
}
